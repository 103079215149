<template>
    <div class="font-body p-5 hover:bg-leaf25 transition duration-500 ease-in-out motion-reduce:transition-none" >
        <div class="font-bold text-forest">
            {{term}}
        </div>
        <div class="">
            {{def}}
        </div>
    </div>
</template>

<script>
export default {
  name: 'GlossaryItem',
  props: {
    term: String,
    def: String
  }
}
</script>